import IdTokenVerifier from "idtoken-verifier";

export const handleDarkToken = (token, companyName) => {
	const verifier = new IdTokenVerifier();
	const tokenData = verifier.decode(token);
	const data = {
		'sub': tokenData.payload.sub,
		'name': tokenData.payload['http://cloud.netapp.com/full_name'],
		"company": companyName,
		"email": tokenData.payload['http://cloud.netapp.com/email'],
		'http://cloud.netapp.com/user_metadata': {
			'name': tokenData.payload['http://cloud.netapp.com/full_name'],
			"company": companyName,
			"email": tokenData.payload['http://cloud.netapp.com/email'],
		},
		tokenData
	}
	return data
}