import {setAccessToken} from "utils/clients";
import _ from "lodash";
import {getItemAnon, setItemAnon, setUserId, removeItemAnon} from "utils/localstorage";
import {handleDarkToken} from 'utils/darkSite';


export default ({dispatch, getState}) => next => async (action) => {
    const { type } = action;

    if (type === 'AUTH:INITIALIZE') {
        dispatch({
            type: "APP:DARK-SITE-LOGIN-PROGRESS"
        });
        const darkLoginToken = getItemAnon({itemKey: 'darkLoginToken'});
        if (darkLoginToken) {
            const about= _.get(getState(), "app.about");
            const tokenData = handleDarkToken(darkLoginToken, about.siteIdentifier.company);
            const payload = {
                accessToken: darkLoginToken,
                "idTokenPayload": tokenData,
                "scope": "",
                "paramsForUI": {}
            }
            const date = new Date();
            if (Math.floor(date.getTime() / 1000) > tokenData.tokenData.payload.exp) {
                dispatch({
                    type: 'APP:DARK-SITE-LOGIN'
                });
            } else {
                dispatch({
                    type: 'AUTH:REFRESH-SSO-SUCCESS',
                    payload: payload
                });

                setAccessToken(darkLoginToken);
                const userId = tokenData.sub;
                setUserId({userId});
                setItemAnon({itemKey: 'darkLoginToken'}, darkLoginToken);

            }
        } else {
            dispatch({
                type: 'APP:DARK-SITE-LOGIN'
            });
        }
    } else if (type === "AUTH:LOGOUT") {
        removeItemAnon({itemKey: 'darkLoginToken'})
        window.location.reload();
    }

    return next(action);
}
